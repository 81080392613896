import React from 'react'
import { Link } from 'gatsby'
import Seo from '@components/seo'
import Main from '@components/main'
import '@styles/object/project/not-found.scss'

const NotFoundPage = () => (

  <Main>
    <Seo title="404 Not found" />
    <div className="not-found">
      <h1 className="not-found__title">404&nbsp;Not&nbsp;Found</h1>
      <p className="not-found__text">申し訳ございません。</p>
      <p className="not-found__text">お探しのページは見つかりませんでした。</p>
      <Link to="/" className="not-found__go-top-page-btn">トップページ</Link>
    </div>
  </Main>
)

export default NotFoundPage
